import React from "react";

import { Card, CardBody } from "reactstrap";
import Fade from "react-reveal/Fade";

import styles from "./custom.module.css";

const FacilitiesCards = (props) => {
  return (
    <>
      <Fade bottom>
        <Card className={`shadow border-0 ${styles.facilitiesCard} bg-yellow`}>
          <CardBody className="py-5 d-flex flex-column align-items-center justify-content-center">
            <div className={`${styles.facilitiesCardImg} mb-4`}>
              <img src={require("assets/img/landing/facilities/sanitize.jpeg")} alt="Sanitization" />
            </div>
            <div className={styles.facilitiesCardTitle}>
              <h2 className={`${styles.childFont} text-default`}>Proper Sanitization</h2>
            </div>
          </CardBody>
        </Card>
      </Fade>
      <Fade bottom>
        <Card className={`shadow border-0 ${styles.facilitiesCard} bg-red`}>
          <CardBody className="py-5 d-flex flex-column align-items-center justify-content-center">
            <div className={`${styles.facilitiesCardImg} mb-4`}>
              <img src={require("assets/img/landing/facilities/cctv.jpg")} alt="CCTV" />
            </div>
            <div className={styles.facilitiesCardTitle}>
              <h2 className={`${styles.childFont} text-yellow`}>CCTV Surveillance</h2>
            </div>
          </CardBody>
        </Card>
      </Fade>
      <Fade bottom>
        <Card className={`shadow border-0 ${styles.facilitiesCard} bg-teal`}>
          <CardBody className="py-5 d-flex flex-column align-items-center justify-content-center">
            <div className={`${styles.facilitiesCardImg} mb-4`}>
              <img src={require("assets/img/landing/facilities/bus.jpeg")} alt="Transport" />
            </div>
            <div className={styles.facilitiesCardTitle}>
              <h2 className={`${styles.childFont} text-default`}>To &amp; Fro Transport</h2>
            </div>
          </CardBody>
        </Card>
      </Fade>
      <Fade bottom>
        <Card className={`shadow border-0 ${styles.facilitiesCard} bg-cyan`}>
          <CardBody className="py-5 d-flex flex-column align-items-center justify-content-center">
            <div className={`${styles.facilitiesCardImg} mb-4`}>
              <img src={require("assets/img/landing/facilities/daycare.jpeg")} alt="Day Care" />
            </div>
            <div className={styles.facilitiesCardTitle}>
              <h2 className={`${styles.childFont} text-default`}>Day Care Facility</h2>
            </div>
          </CardBody>
        </Card>
      </Fade>
      <Fade bottom>
        <Card className={`shadow border-0 ${styles.facilitiesCard} bg-orange`}>
          <CardBody className="py-5 d-flex flex-column align-items-center justify-content-center">
            <div className={`${styles.facilitiesCardImg} mb-4`}>
              <img src={require("assets/img/landing/facilities/trip.jpeg")} alt="Trips" />
            </div>
            <div className={styles.facilitiesCardTitle}>
              <h2 className={`${styles.childFont} text-yellow`}>Educational &amp; Fun Trips</h2>
            </div>
          </CardBody>
        </Card>
      </Fade>
      <Fade bottom>
        <Card className={`shadow border-0 ${styles.facilitiesCard} bg-yellow`}>
          <CardBody className="py-5 d-flex flex-column align-items-center justify-content-center">
            <div className={`${styles.facilitiesCardImg} mb-4`}>
              <img src={require("assets/img/landing/facilities/play.jpeg")} alt="Play" />
            </div>
            <div className={styles.facilitiesCardTitle}>
              <h2 className={`${styles.childFont} text-default`}>Outdoor &amp; Indoor Play</h2>
            </div>
          </CardBody>
        </Card>
      </Fade>
      <Fade bottom>
        <Card className={`shadow border-0 ${styles.facilitiesCard} bg-red`}>
          <CardBody className="py-5 d-flex flex-column align-items-center justify-content-center">
            <div className={`${styles.facilitiesCardImg} mb-4`}>
              <img src={require("assets/img/landing/facilities/bday.jpeg")} alt="Bday Celebration" />
            </div>
            <div className={styles.facilitiesCardTitle}>
              <h2 className={`${styles.childFont} text-yellow`}>Festival &amp; BDay Celebration</h2>
            </div>
          </CardBody>
        </Card>
      </Fade>
      <Fade bottom>
        <Card className={`shadow border-0 ${styles.facilitiesCard} bg-teal`}>
          <CardBody className="py-5 d-flex flex-column align-items-center justify-content-center">
            <div className={`${styles.facilitiesCardImg} mb-4`}>
              <img src={require("assets/img/landing/facilities/art.jpeg")} alt="Art &amp; Craft" />
            </div>
            <div className={styles.facilitiesCardTitle}>
              <h2 className={`${styles.childFont} text-default`}>Art &amp; Craft</h2>
            </div>
          </CardBody>
        </Card>
      </Fade>
    </>
  )
};

export default FacilitiesCards;