import React, { useEffect } from "react";
import {
  Row,
  Col
} from "reactstrap";
import Fade from "react-reveal/Fade";

import ContentPageCover from "views/ContentPage/ContentPageCover";
import ContentContainer from "views/ContentPage/ContentContainer";
import TeamMember from "./TeamMember";
import PageTransition from "../PageTransition/PageTransition";

const About = (props) => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  return (
    <>
      <PageTransition {...props} bgColor="#7bd0a5" bgImg={require('assets/img/transitions/transition_pattern.png')}>
        <ContentPageCover bgColor="#2c925f" title="About Us" patternImg={require('assets/img/landing/classes_pattern.png')} />
        <ContentContainer patternImg={require('assets/img/about/content_pattern.png')}>
          <div className="d-flex flex-column">
            <h2 className="font-weight-800 about-heading">
              About Aadhaar
          </h2>
            <p className="font-weight-500">
              Aadhaar is a Playschool and Daycare centre situated in the heart of Indirapuram. It is headed by an experienced team of over 20 years of experience managing Aadhaar Montessori School. This enables them with a practical approach towards bringing out the best in kids in the most child centric manner. Realizing the need for a trustworthy Preschool and Daycare in society, our philosophy is to focus on the happiness of the child and satisfaction of the parent. Here children are provided with opportunities to participate in enriching and challenging experiences, based on having fun and learning through play. Early learning centre sets to make playschool experience memorable while grooming the younger generation to be lifelong learners and solution providers. All this is achieved through our creative curriculum and dedicated staff who are passionate about imparting positive attributes to children under our care.
          </p>
          </div>

          <Row className="mt-5 d-flex flex-column align-items-center">
            <h2 className="font-weight-800 about-heading">
              Meet Our Team
          </h2>
            <Row className="mt-3 d-flex justify-content-center">
              {/* <Fade bottom>
                <TeamMember imgUrl={require("assets/img/about/arunsehgal.png")} name="Arun Sehgal" about="With over 15 years of experience, Mr. Arun is the principle of Aadhaar Playway and Aadhaar Montessori School. He also runs his own tuition from classes 6 to 10." />
              </Fade> */}
              <Fade bottom>
                <TeamMember imgUrl={require("assets/img/about/jyotisehgal.png")} name="Jyoti Sehgal" about="With over 15 years of experience, Jyoti manages daycare at Aadhaar. She knows her way with kids and children love to play with her." />
              </Fade>
              <Fade top>
                <TeamMember imgUrl={require("assets/img/about/soniasehgal.png")} name="Sonia Sehgal" about="Sonia teaches pre-nursery at Aadhaar. With here vast experience, she knows how to take care of your child with kindness and affection." />
              </Fade>
            </Row>
          </Row>

          <Row className="row-grid align-items-center">
            <Col className="order-md-1 d-flex flex-column justify-content-begin" md="6">
              <h2 className="font-weight-800 about-heading">Our Mission</h2>
              <ul className="font-weight-500">
                <li className="mb-2">To provide adequate healthy environment for kids for their overall development</li>
                <li className="mb-2">To maintain the ambience of homely atmosphere</li>
                <li className="mb-2">To prepare the toddlers to become happy and curious kids</li>
                <li className="mb-2">To enrich the power of self talent hunting</li>
                <li className="mb-2">To involve children in both mental and physical activity</li>
              </ul>
            </Col>
            <Col className="order-md-2 d-flex justify-content-center align-items-center" md="6">
              <Fade right>
                <img
                  alt="Our Mission"
                  className="img-fluid floating border-0 content-img"
                  src={require("assets/img/about/mission.png")}
                />
              </Fade>
            </Col>
          </Row>
          <Row className="row-grid align-items-center">
            <Col className="order-md-2 d-flex flex-column justify-content-begin" md="6">
              <h2 className="font-weight-800 about-heading">Our Vision</h2>
              <p className="font-weight-500">
                Our vision is to enhance the overall development of the child through designed pedagogy to help realize their unique abilities in systematic, synergetic and self paced manner, prepare to sync in the high level social, emotional, linguistic and other developmental milestones. We prepare child to discover their own individual learning style and achieve the desired learning outcome.
              </p>
            </Col>

            <Col className="order-md-1 d-flex justify-content-center align-items-center" md="6">
              <Fade left>
                <img
                  alt="Our Values"
                  className="img-fluid floating border-0 content-img"
                  src={require("assets/img/about/values.png")}
                />
              </Fade>
            </Col>
          </Row>
          <Row className="row-grid align-items-center">
            <Col className="order-md-1 d-flex flex-column justify-content-begin" md="6">
              <h2 className="font-weight-800 about-heading">Our Goal</h2>
              <ul className="font-weight-500">
                <li className="mb-2">Nurturing the unique potential of every child</li>
                <li className="mb-2">Shaping children into happy, curious, thinking, dynamic and “ready for life” individuals</li>
                <li className="mb-2">Encouraging peer interaction and individual growth</li>
              </ul>
            </Col>

            <Col className="order-md-2 d-flex justify-content-center align-items-center" md="6">
              <Fade right>
                <img
                  alt="Our Goal"
                  className="img-fluid floating border-0 content-img"
                  src={require("assets/img/about/goal.png")}
                />
              </Fade>
            </Col>
          </Row>
        </ContentContainer>
      </PageTransition>
    </>
  );
}

export default About;