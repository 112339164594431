import React from "react";
import {
  Container,
} from "reactstrap";

import styles from "./styles.module.css";

const ContentPageCover = (props) => {
  return (
    <>
      <div className={`position-relative ${styles.cover}`} style={{
        backgroundColor: props.bgColor || "#2c925f",
        backgroundImage: `url(${props.patternImg})`
      }}>
        <Container className="d-flex align-items-center py-lg mt-5 justify-content-center text-center">
          <h1 className="child-font font-weight-800" style={{ color: props.titleColor || "#ffffff", fontSize: "4rem" }}>
            {props.title}
          </h1>
        </Container>
      </div>
      <div className={styles.waveContainer}>
        {/* SVG separator */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 150">
          <rect width="100%" height="100%" fill="#eeeeee" />
          <path fill={props.bgColor || "#2c925f"} fill-opacity="1" d="M0,32L80,37.3C160,43,320,53,480,48C640,43,800,21,960,16C1120,11,1280,21,1360,26.7L1440,32L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z">
          </path>
        </svg>
      </div>
    </>
  )
}

export default ContentPageCover;