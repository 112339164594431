import React, { useEffect, useState } from "react";
import {
  Col,
  FormGroup,
  Input,
  Button,
  Alert
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";

import styles from "./styles.module.css";

import ContentPageCover from "views/ContentPage/ContentPageCover";
import ContentContainer from "views/ContentPage/ContentContainer";
import PageTransition from "views/PageTransition/PageTransition";

const Contact = (props) => {
  const recaptchaRef = React.createRef();
  const [isDisabled, submitBtnDisableHandler] = useState(false);
  const [buttonText, submitBtnTextHandler] = useState("Submit");
  const [contactName, nameHandler] = useState("");
  const [contactPhone, phoneHandler] = useState("");
  const [contactMsg, msgHandler] = useState("");
  const [showAlert, alertHandler] = useState(false);
  const [alertText, alertTextHandler] = useState("");
  const [alertColor, alertColorHandler] = useState("");
  const [isCaptchaValidated, captchaValidationHandler] = useState(false);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const validateForm = () => {
    // Note: rulesn should be in decreasing order of strictness
    let isError = false;
    let errorMsg = "";
    if (!contactPhone.match(/^\d{10}$/)) {
      isError = true;
      errorMsg = "Please enter valid 10 digit phone number (eg: 9871331882).";
    }
    if (contactName.trim() === "" || contactPhone.trim() === "" || contactMsg.trim() === "") {
      isError = true;
      errorMsg = "Please enter all fields";
    }

    if (isError) {
      alertHandler(true);
      alertColorHandler("danger");
      alertTextHandler(errorMsg);
      return false;
    }

    return true;
  }

  const onFormSubmit = async (e) => {
    e.preventDefault();
    alertHandler(false);
    if (!validateForm())
      return;

    if (!isCaptchaValidated)
      await recaptchaRef.current.executeAsync();

    captchaValidationHandler(true);
    submitBtnDisableHandler(true);
    submitBtnTextHandler("Submitting...");
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", contactName, contactPhone, contactMsg })
    })
      .then((res) => {
        submitBtnDisableHandler(false);
        nameHandler("");
        phoneHandler("");
        msgHandler("");
        submitBtnTextHandler("Submit");
        alertTextHandler("Thanks for reaching out! We will be in touch within 48 hours.");
        alertColorHandler("success");
        alertHandler(true);
      })
      .catch(error => {
        submitBtnDisableHandler(false);
        submitBtnTextHandler("Submit");
        alertTextHandler("Oops, some error occurred! Please try again or reach us at aadhaarschool155@gmail.com.");
        alertColorHandler("danger");
        alertHandler(true);
        console.log(error);
      });
  }

  const inputHandler = async (e) => {
    if (e.target.name === "contactName") {
      nameHandler(e.target.value);
    } else if (e.target.name === "contactPhone") {
      phoneHandler(e.target.value);
    } else if (e.target.name === "contactMsg") {
      msgHandler(e.target.value);
    }
  }

  return (
    <>
      <PageTransition {...props} bgColor="#b946c1" bgImg={require('assets/img/landing/classes_pattern.png')}>
        <ContentPageCover bgColor="#b946c1" title="Contact Us" patternImg={require('assets/img/landing/classes_pattern.png')} />
        <ContentContainer patternImg={require('assets/img/about/content_pattern.png')} className="d-flex flex-column">
          <div className={`mb-4 d-flex justify-content-between ${styles.contactContainer}`}>
            <Col md="6" className="d-flex flex-column mb-3">
              <div className="mb-2">
                <div className={`d-flex align-items-center mb-2 font-weight-600 ${styles.iconHeading}`}>
                  <i className="fa fa-phone mr-2" />
                  <span>Phone</span>
                </div>
                <div className="d-flex flex-column">
                  {/* <span className="mb-1 font-weight-600">+91 99719 69283 (Arun Sehgal)</span> */}
                  <span className="mb-1 font-weight-600">+91 96544 39392 (Jyoti Sehgal)</span>
                  <span className="mb-1 font-weight-600">+91 98990 33182 (Sonia Sehgal)</span>
                </div>
              </div>
              <div className="mb-2">
                <div className={`d-flex align-items-center mb-2 font-weight-600 ${styles.iconHeading}`}>
                  <i className="fa fa-envelope mr-2" />
                  <span>E-mail</span>
                </div>
                <span className="mb-1 font-weight-600">aadhaarschool155@gmail.com</span>
              </div>
              <div className="mb-2">
                <div className={`d-flex align-items-center mb-2 font-weight-600 ${styles.iconHeading}`}>
                  <i className="fa fa-map-marker mr-2" />
                  <span>Address</span>
                </div>
                <span className="mb-1 font-weight-600 d-block">Plot No. 155, Shakti Khand 1, Indirapuram, Ghaziabad, Uttar Pradesh 201014</span>
                <span className="mb-1 font-weight-800"><a href="https://goo.gl/maps/AvrHVBGCA2D6n4Kc7" target="_blank" rel="noopener noreferrer">Find us on Google Maps</a></span>
              </div>
            </Col>
            <Col md="6" className="d-flex flex-column ">
              <h3>Get in Touch</h3>
              <FormGroup>
                <Input
                  id="formName"
                  placeholder="Full Name"
                  type="text"
                  name="contactName"
                  value={contactName}
                  onChange={inputHandler}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  id="formPhone"
                  placeholder="Contact Number"
                  type="text"
                  name="contactPhone"
                  value={contactPhone}
                  onChange={inputHandler}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  id="formMessage"
                  placeholder="Message"
                  type="textarea"
                  rows="6"
                  name="contactMsg"
                  value={contactMsg}
                  onChange={inputHandler}
                />
              </FormGroup>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LdVrhMdAAAAAH5iRTTO7OWPxzByJUQ97Z5Pg__O"
              />
              <Button color="success" size="lg" type="button" onClick={onFormSubmit} disabled={isDisabled}>
                {buttonText}
              </Button>
              {
                showAlert ? (
                  <Alert color={alertColor} className="mt-2 font-weight-500">
                    {alertText}
                  </Alert>
                ) : null
              }
            </Col>
          </div>
        </ContentContainer>
      </PageTransition>
    </>
  );
}

export default Contact;