import React from "react";
// import { Link } from "react-router-dom";

import {
  Container,
  Row,
  Col
} from "reactstrap";
import styles from "./custom.module.css";

const Footer = (props) => {
  return (
    <>
      <section className={`mt-0 pt-5 justify-content-center ${styles.footer}`}>
        <Container className={`pt-3 ${styles.footerContainer}`}>
          {
            props.landing === true ?
              (
                <Row className={`d-flex justify-content-around ${styles.footerImgRow}`}>
                  <a href="https://www.facebook.com/RootzPlayandDayCare/photos/" target="_blank" rel="noopener noreferrer">
                    <img src={require('assets/img/footer/gallery.jpg')} alt="Gallery" className={`card-lift--hover shadow border-0 ${styles.footerImg}`} />
                  </a>
                  {/* <Link to="/blog">
                    <img src={require('assets/img/footer/blog.jpg')} alt="Blog" className={`card-lift--hover shadow border-0 ${styles.footerImg}`} />
                  </Link> */}
                </Row>
              )
              : null
          }
          <Row className={`mt-3 d-flex justify-content-around ${styles.footerContent}`}>
            <Col className="d-flex justify-content-center">
              <img src={require('assets/img/transition_logo.png')} alt="Aadhaar Montessori School"></img>
            </Col>
            <Col>
              <h3>Locate Us</h3>
              <span className="mb-1 d-block">Plot No. 155, Shakti Khand 1, Indirapuram, Ghaziabad, Uttar Pradesh 201014</span>
              <span className="mb-1"><a href="https://goo.gl/maps/AvrHVBGCA2D6n4Kc7" target="_blank" rel="noopener noreferrer">Find us on Google Maps</a></span>
            </Col>
            <Col>
              <h3>Contact Us</h3>
              <Row className="d-flex align-items-center mb-2">
                <i className="fa fa-phone mr-2" />
                <span>+91 96544 39392 (Jyoti Sehgal)</span>
              </Row>
              <Row className="d-flex align-items-center mb-2">
                <i className="fa fa-map-marker mr-2" />
                <span>Plot No. 155, Shakti Khand 1, Indirapuram, Ghaziabad, Uttar Pradesh 201014</span>
              </Row>
              <Row className="d-flex align-items-center mb-2">
                <i className="fa fa-facebook-official mr-2" />
                <span><a href="https://www.facebook.com/RootzPlayandDayCare" target="_blank" rel="noopener noreferrer">Visit Facebook</a></span>
              </Row>
            </Col>
          </Row>
          <Row className={`mt-4 ${styles.footerCopyrightContainer}`}>
            <Col className="d-flex justify-content-center">
              &copy; Aadhaar Montessori School, {new Date().getFullYear()}
            </Col>
            <Col className="d-flex justify-content-center">
              <p className={styles.footerRights}>
                Aadhaar holds sole rights to the content of this website, including but not limited to the blog and gallery. Seek prior approval before publishing.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Footer;