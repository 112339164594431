import React from "react";
import { motion } from "framer-motion";

import styles from "./styles.module.css";

const box = {
  initial: {
    height: 0,
    width: "100%",
    bottom: 0,
  },
  animate: {
    height: ["0%", "100%", "100%", "0%"],
    transition: {
      duration: 3,
      ease: [0.87, 0, 0.13, 1],
    },
  }
}

// const logo = {
//   initial: {
//     scale: 0
//   },
//   animate: {
//     scale: [0, 1, 0],
//     transition: {
//       duration: 2.5,
//       ease: [0.87, 0, 0.13, 1],
//     },
//   }
// }

// const svgCharStroke = {
//   initial: {
//     pathLength: 0,
//     pathOffset: 1,
//     fill: "transparent"
//   },
//   animate: {
//     pathLength: 1,
//     pathOffset: 0,
//     fill: "transparent",
//     transition: {
//       duration: 1,
//       delay: 0.5
//     }
//   },
// }

// const fillTransitionDuration = 0.5;
// const fillTransitionDelay = 1.3;

const PageTransition = (props) => {
  return (
    <>
      <motion.section key={`section${props.location.pathname}`} exit={{ opacity: 0, transition: { duration: 1.1, ease: [0.87, 0, 0.13, 1], } }}>
        <motion.div
          key={`bgBox${props.location.pathname}`}
          className={`position-fixed d-flex justify-content-center align-items-center ${styles.box}`}
          style={{
            backgroundImage: `url(${props.bgImg})`,
            backgroundColor: props.bgColor,
          }}
          initial="initial"
          animate="animate"
          variants={box}
          onAnimationStart={() => {
            document.body.classList.add("overflow-hidden");
            document.body.style.backgroundColor = props.bgColor;
            document.getElementById("transition-logo").style.zIndex = "block";
          }}
          onAnimationComplete={() => {
            document.body.classList.remove("overflow-hidden");
            document.getElementById("transition-logo").style.display = "none";
          }
          }
        >
        <motion.div
          id="transition-logo"
          initial={{ opacity: 0, scale: 1 }}
          animate={{
            scale: [0, 1.1, 1, 1],
            opacity: [1, 1, 1, 0],
          }}
          transition={{ duration: 2.5 }}
        >
          <img className={styles.logo} src={require('assets/img/transition_logo.png')} alt="Aadhaar Motessori School"></img>
        </motion.div>
        </motion.div>
        {props.children}
      </motion.section>
    </>
  );
}

export default PageTransition;