import React from "react";

import { Container, Row } from "reactstrap";

import styles from "./custom.module.css";
import FacilitiesCards from "./FacilitiesCards";

class Facilities extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }

  render() {
    return (
      <>
        <section className={`mt-0 pb-8 justify-content-center ${styles.bgPattern}`} style={{ backgroundColor: "#2dce89", backgroundImage: `url(${require('assets/img/landing/facilities_pattern.png')})` }}>
          <Container className="pt-3">
            <div className="d-flex align-items-center mb-4">
              <img src={require("assets/img/landing/kids-play-2.svg")} className={styles.titleSvg} alt="Our Facilities" />
              <h1 className={`font-weight-800 ${styles.adultFont}`}>Our Facilities</h1>
            </div>
            <p className="font-weight-600 text-default">
              We give access to best facilities to make sure your child enjoys their time at Aadhaar and you spend your day without worry.
            </p>
            <Row className="d-flex justify-content-center mt-5">
              <FacilitiesCards />
            </Row>
          </Container>
        </section>
        <div className={styles.waveContainer}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 160">
            <rect width="100%" height="100%" fill="#2bffc6"></rect>
            <path fill="#2dce89" fill-opacity="1" d="M0,32L80,58.7C160,85,320,139,480,144C640,149,800,107,960,101.3C1120,96,1280,128,1360,144L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
          </svg>
        </div>
      </>
    )
  }
}

export default Facilities;