import React from "react";

import Fade from "react-reveal/Fade";

import styles from "./styles.module.scss";

const PorgramsCard = (props) => {
  const { bgColor, title, darkBg, description, img } = props.data;

  return (
    <Fade bottom>
      <div className={styles.programsCardContainer} style={{ backgroundColor: bgColor ? bgColor : "#eeeeee" }}>
        <img src={img} alt={title} />
        <div className={styles.programsCardContent}>
          <span style={{ color: darkBg ? "#f0e673" : "#172b4d" }}>{title}</span>
          <p style={{ color: darkBg ? "#d9e85e" : "#172b4d" }}>{description}</p>
        </div>
      </div>
    </Fade>
  )
};

export default PorgramsCard;