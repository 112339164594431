import React from "react";

// reactstrap components
import Carousel from "./Carousel.js";
import Classes from "./Classes.js";
import Facilities from "./Facilities.js";
import Testimony from "./Testimony.js";
import PageTransition from "../PageTransition/PageTransition.js";

class Home extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }

  render() {
    return (
      <>
        <PageTransition {...this.props} bgColor="#F0E673" bgImg={require('assets/img/transitions/transition_pattern.png')}>
          <Carousel />
          <Classes />
          <Facilities />
          <Testimony />
        </PageTransition>
      </>
    )
  }
}

export default Home;