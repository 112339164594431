import React, { useState } from "react";

import {
  Container,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";
import styles from "./custom.module.css";

const Testimony = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const items = [
    {
      src: `${require('assets/img/landing/testimonials/1.png')}`,
      altText: 'Testimony 1',
      testimony: 'Best daycare facility in Indirapuram. The school is filled with activities and my daughter is never bored.',
    },
    {
      src: `${require('assets/img/landing/testimonials/2.png')}`,
      altText: 'Testimony 2',
      testimony: 'The teachers are very caring and great with children. My elder son studied in their previous school and now my younger one is at Aadhaar.',
    },
    {
      src: `${require('assets/img/landing/testimonials/3.png')}`,
      altText: 'Testimony 3',
      testimony: 'My children enjoy their time at Aadhaar with all the fun activities like Art & Craft and Ball Pool.',
    }
  ];

  const slides = items.map((item) => {
    return (
      <CarouselItem
        key={item.src}
      >
        <Container className={`d-flex justify-content-between align-items-center ${styles.testimonySliderItem}`}>
          <img src={item.src} alt={item.altText} />
          <div className={styles.testimonyContent}>
            <img src={require('assets/img/landing/testimonials/quote.png')} alt="Quote" />
            <p className={`font-weight-600 ${styles.childFont}`}>
              {item.testimony}
            </p>
          </div>
        </Container>
      </CarouselItem>
    );
  });

  return (
    <>
      <section className={`mt-0 pt-5 pb-8 justify-content-center ${styles.bgPattern}`} style={{ backgroundColor: "#2bffc6" }}>
        <Container className="pt-3">
          <h1 className={`font-weight-800 ${styles.adultFont}`}>Parents Speak</h1>
          <p className="font-weight-600 text-default">
            Parents love our previous school Aadhaar and we are sure they will love Aadhaar even more.
          </p>
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={5000}
            pause={false}
            ride='carousel'
          >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} className={styles.testimonySliderIndicators} />
            {slides}
          </Carousel>
        </Container>
      </section>
      <div className={styles.waveContainer}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 180">
          <rect fill="#f4f5f7" width="100%" height="100%"></rect>
          <path fill="#2bffc6" fill-opacity="1" d="M0,160L1440,32L1440,0L0,0Z"></path>
        </svg>
      </div>
    </>
  );
}

export default Testimony;