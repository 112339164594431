import React from "react";

import { Container, Row, Col, Card, CardBody, Badge, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import styles from "./custom.module.css";

class Classes extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }

  render() {
    return (
      <>
        <section className={`mt-0 pb-4 justify-content-center ${styles.childFont} ${styles.bgPattern}`} style={{ backgroundColor: "#f0e673", backgroundImage: `url(${require('assets/img/landing/classes_pattern.png')})` }}>
          <Container>
            <div className="d-flex align-items-center mb-4">
              <img src={require("assets/img/landing/kids-play-svg.svg")} className={styles.titleSvg} alt="Our Classes" />
              <h1 className={`font-weight-800 ${styles.adultFont}`}>Our Classes</h1>
            </div>
            <section className="pt-lg-0 mt-2">
              <Container>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <Row className="row-grid">
                      <Col lg="4">
                        <Fade top>
                          <Card className={`shadow border-0 ${styles.classCard} bg-primary`}>
                            <CardBody className="py-5">
                              <div className={`${styles.classes_img} mb-4`}>
                                <img src={require("assets/img/landing/programs/prenursery.jpeg")} alt="Pre Nursery" />
                              </div>
                              <h6 className="text-yellow text-uppercase font-weight-800">
                                Pre Nursery
                            </h6>
                              <p className="text-yellow font-weight-400 description mt-3">
                                For children of ages 1.5 to 3 years just entering their world of education and play. The fun-filled classes usually run from 9 AM to 12 Noon and involve a mix of practical activities and theoretical learning.
                            </p>
                              <div>
                                <Badge color="warning" pill className="mr-1">
                                  1.5 - 3 yrs
                            </Badge>
                                <Badge color="warning" pill className="mr-1">
                                  3 hrs/day
                            </Badge>
                              </div>
                              <Button
                                className="mt-4"
                                color="warning"
                                to="/contact"
                                tag={Link}
                              >
                                Equire Now
                          </Button>
                            </CardBody>
                          </Card>
                        </Fade>
                      </Col>
                      <Col lg="4">
                        <Fade bottom>
                          <Card className={`shadow border-0 ${styles.classCard} bg-green`}>
                            <CardBody className="py-5">
                              <div className={`${styles.classes_img} mb-4`}>
                                <img src={require("assets/img/landing/programs/nursery.jpeg")} alt="Nursery" />
                              </div>
                              <h6 className="text-default text-uppercase font-weight-800">
                                Nursery &amp; Prep
                          </h6>
                              <p className="text-default font-weight-400 description mt-3">
                                For children wanting to have a fun-filled school experience. The classes are a mix of studies, fun activities and outdoor trips.
                          </p>
                              <div>
                                <Badge color="default" pill className="mr-1">
                                  Nursery: 3+ yrs
                            </Badge>
                            <Badge color="default" pill className="mr-1">
                                  Prep: 4+ yrs
                            </Badge>
                                <Badge color="default" pill className="mr-1">
                                  3 hrs/day
                            </Badge>
                              </div>
                              <Button
                                className="mt-4"
                                color="default"
                                to="/contact"
                                tag={Link}
                              >
                                Enquire Now
                          </Button>
                            </CardBody>
                          </Card>
                        </Fade>
                      </Col>
                      <Col lg="4">
                        <Fade bottom>
                          <Card className={`shadow border-0 ${styles.classCard} bg-blue`}>
                            <CardBody className="py-5">
                              <div className={`${styles.classes_img} mb-4`}>
                                <img src={require("assets/img/landing/programs/daycare.jpeg")} alt="Day Care" />
                              </div>
                              <h6 className="text-yellow text-uppercase font-weight-800">
                                Day Care
                          </h6>
                              <p className="description text-yellow font-weight-400 mt-3">
                                Do your work free of worry while we take care of your child as one of our own. We provide a safe environment with fun activities for your child after school.
                          </p>
                              <div>
                                <Badge color="warning" pill className="mr-1">
                                  1+ yrs
                                </Badge>
                                {/* <Badge color="warning" pill className="mr-1">
                                  3 - 4 hrs/day
                            </Badge> */}
                              </div>
                              <Button
                                className="mt-4"
                                color="warning"
                                to="/contact"
                                tag={Link}
                              >
                                Enquire Now
                          </Button>
                            </CardBody>
                          </Card>
                        </Fade>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </section>
          </Container>
        </section>
        <div className={styles.waveContainer}>
          {/* SVG separator */}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 180">
            <rect width="100%" height="100%" fill="#2dce89"></rect>
            <path fill="#f0e673" fill-opacity="1" d="M0,160L1440,64L1440,0L0,0Z"></path>
          </svg>
        </div>
      </>
    )
  }
}

export default Classes;