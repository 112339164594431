/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import styles from "./custom.module.css";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledCarousel } from "reactstrap";

const items = [
  {
    src: require("assets/img/theme/landing-carousel/1.jpeg"),
    altText: "",
    caption: "",
    header: ""
  },
  {
    src: require("assets/img/theme/landing-carousel/2.jpeg"),
    altText: "",
    caption: "",
    header: ""
  },
  {
    src: require("assets/img/theme/landing-carousel/3.jpeg"),
    altText: "",
    caption: "",
    header: ""
  }
];

class Carousel extends React.Component {
  render() {
    return (
      <>
        <div>
          <div className={`position-relative ${styles.cover}`} style={{ backgroundImage: `url(${require('assets/img/landing/cover_bg.png')})` }}>
            <Container className="d-flex align-items-center py-lg mt-5">
              <Row className="justify-content-between align-items-center">
                <Col className="mb-5 mb-lg-0 text-center" lg="4">
                  <img className={styles.coverLogo} src={require('assets/img/brand/aadhaar_yellow.png')} alt="Aadhaar Montessori School"></img>
                  <p className={`lead mt-2 font-weight-400 ${styles.carouselSubtext}`}>
                    Located in the heart of Indirapuram and managed by a team having over 20 years of experience.
                </p>
                  <Row className="mt-4 d-flex justify-content-center">
                    <Button
                      color="default"
                      to="/about"
                      tag={Link}
                    >
                      About Us
                    </Button>
                    <Button
                      color="default"
                      to="/contact"
                      tag={Link}
                    >
                      Contact Us
                    </Button>
                  </Row>
                </Col>
                <Col lg="7">
                  <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                    <UncontrolledCarousel items={items} />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className={styles.waveContainer}>
            {/* SVG separator */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <rect width="100%" height="100%" fill="#f0e673" />
              <path fill="#E62767" fill-opacity="1" d="M0,160L40,181.3C80,203,160,245,240,229.3C320,213,400,139,480,138.7C560,139,640,213,720,245.3C800,277,880,267,960,218.7C1040,171,1120,85,1200,58.7C1280,32,1360,64,1400,80L1440,96L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>
            </svg>
          </div>
        </div>
      </>
    );
  }
}

export default Carousel;
