import React from "react";

import { Container } from "reactstrap";
import styles from "./styles.module.css";

const ContentContainer = (props) => {
  return (
    <div className={`d-flex flex-column pb-5 ${styles.contentContainer}`} style={{ backgroundImage: `url(${props.patternImg})` || "none" }}>
      <Container className={`pr-5 pl-5 ${props.className}`}>
        {props.children}
      </Container>
    </div>
  );
};

export default ContentContainer;