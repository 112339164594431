import React, { useEffect } from "react";
import { Link, Redirect, useParams } from "react-router-dom";

import styles from "./styles.module.scss";

import ContentPageCover from "views/ContentPage/ContentPageCover";
import ContentContainer from "views/ContentPage/ContentContainer";
import PageTransition from "../PageTransition/PageTransition";
import FacilitiesCards from "views/Home/FacilitiesCards";
import PorgramsCard from "./ProgramsCard";

const programData = {
  "toddlers": {
    "age": "1 - 2 yrs",
    "duration": "2 - 2.5 hrs",
    "details": "Aadhaar offers the best ‘parent and guardian accompanied’ programmes for toddlers to help strengthen the parent-child and family-child relationship, and to also help prepare toddlers for preschool, playschool, and nursery. Through Aadhaar programmes, children enjoy creative, interactive, fun and engaging sensory driven experiences. These experiences help toddlers build their senses, develop fine motor skills, hand and eye coordination, imaginative thinking and many other important skills.",
    "image": require("assets/img/brand/navbar.png"),
  },
  "pre-nursery": {
    "age": "2 - 3 yrs",
    "duration": "2 - 2.5 hrs",
    "details": "To be filled.",
    "image": require("assets/img/brand/navbar.png"),
  },
  "day-care": {
    "age": "1.5 - 3 yrs",
    "duration": "2 - 2.5 hrs",
    "details": `With a young child that needs constant attention, stimulative engagement and also informal learning, day care becomes the answer for working parents who want to simultaneously pursue their career during the child raising years.\n
    Aadhaar aims to become the bridge between your child's early years at home and preschool, play school, or nursery. They help children to adapt to a new place, learn new words and activities, and become familiar with a learning environment.\n
    Aadhaar provides parents of young kids a healthy happy environment where children may have the opportunity to explore and develop their own unique individuality while helping to better prepare them for a happier adjustment into kindergarten.`,
    "image": require("assets/img/brand/navbar.png"),
  },
}

const rootzDayData = [
  {
    title: "Greeting Time",
    description: "Petals Facilitator stands with puppet in hand to welcome our little Petalians at the gate. Each Petalian is welcomed with a smile to make them feel connected and special. Petalians choose the way to enter the classroom through high five, dance, hug, shake hands etc. This creates fun in the class.",
    img: require("assets/img/brand/navbar.png"),
    bgColor: "#f0e673",
    darkBg: false
  },
  {
    title: "Circle Time",
    description: "Our Petalians start their day with circle time to develop social interaction and positive relationships among peer group. It improvises listening skills, fine motor skills and gross motor skills. Circle Time motivates Petalians to attend school daily. During circle time, our Petalians do meditation activity along with tongue twister and concept clearance.",
    img: require("assets/img/brand/navbar.png"),
    bgColor: "#E62767",
    darkBg: true
  },
  {
    title: "Rhyme Time",
    description: "At Petals, rhymes are done with music and dance to enhance musical skills. Petalians enjoy this session with their facilitators, props and peer group.",
    img: require("assets/img/brand/navbar.png"),
    bgColor: "#11cdef",
    darkBg: false
  },
  {
    title: "Tummy Time",
    description: "Petals provide healthy and nutritious meal to Petalians. Meal planner is designed under the able guidance of a nutritionist from AIIMS. Healthy meal and snacks allow children to enjoy eating healthy food in an encouraging social setting.",
    img: require("assets/img/brand/navbar.png"),
    bgColor: "#5603ad",
    darkBg: true
  },
];

const Programs = (props) => {
  const { programName } = useParams();
  const allowedPrograms = ["toddlers", "day-care", "pre-nursery"];

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  const getTitle = () => {
    switch (programName) {
      case "toddlers":
        return "Toddlers";
      case "day-care":
        return "Day Care";
      case "pre-nursery":
        return "Pre-Nursery";
      default:
        return "";
    }
  }

  if (!allowedPrograms.includes(programName)) {
    return <Redirect to="/" />;
  }

  const data = programData[programName];

  return (
    <>
      <PageTransition {...props} bgColor="#1CC2E6" bgImg={require('assets/img/transitions/transition_pattern.png')}>
        <ContentPageCover bgColor="#1CA3E6" title={`Our Programs: ${getTitle()}`} patternImg={require('assets/img/landing/classes_pattern.png')} />
        <ContentContainer patternImg={require('assets/img/about/content_pattern.png')}>
          <div className={styles.metaContainer}>
            <div className={styles.metaContent}>
              <h1 className="font-weight-800">{getTitle()}</h1>
              <div className="font-weight-500 mb-2">
                <span>{data.details}</span>
              </div>
              <div className={styles.infoBadge}>
                <i className="fa fa-child mr-2" />
                <span>{data.age}</span>
              </div>
              <div className={styles.infoBadge}>
                <i className="fa fa-clock-o mr-2" />
                <span>{data.duration}</span>
              </div>
            </div>
            <img src={data.image} alt={programName} className={`${styles.metaImg} shadow floating`} />
          </div>
          <div className="mt-4 d-flex flex-column">
            <h1 className="font-weight-800">Enquire Now</h1>
            <p className="font-weight-500">
              We would love to welcome you and your child to the Aadhaar family, <Link to="/contact">enquire now</Link>.
            </p>
          </div>
          <div className="mt-4 d-flex flex-column">
            <h1 className="font-weight-800">A day at Aadhaar</h1>
            {rootzDayData.map(data => (
              <PorgramsCard data={data} />
            ))}
          </div>
          <div className="mt-4">
            <h1 className="font-weight-800">Our Facilities</h1>
            <div className="d-flex flex-wrap justify-content-center">
              <FacilitiesCards />
            </div>
          </div>
        </ContentContainer>
      </PageTransition>
    </>
  );
}

export default Programs;