/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import Home from "views/Home/Home";
import About from "views/About/About";
import NavBar from "views/Home/NavBar";
import Footer from "views/Home/Footer";
import Contact from "views/Contact/Contact";
import Programs from "views/Programs/Programs";

ReactDOM.render(
  <BrowserRouter>
    <Route render={({ location }) => (
      <AnimatePresence initial={true}>
        <NavBar key="NavBar" />
        <Switch location={location} key={location.pathname}>
          <Route
            path="/"
            exact
            render={props => <Home {...props} />}
          />
          <Route
            path="/about"
            exact
            render={props => <About {...props} />}
          />
          <Route
            path="/contact"
            exact
            render={props => <Contact {...props} />}
          />
          <Route
            path="/programs/:programName"
            exact
            render={props => <Programs {...props} />}
          />
          <Redirect to="/" />
        </Switch>
        <Footer landing={location.pathname === "/"} />
      </AnimatePresence>
    )} />
  </BrowserRouter>,
  document.getElementById("root")
);
