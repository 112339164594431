import React from "react";

import styles from "./styles.module.css";

const TeamMember = (props) => {
  return (
    <div className={`d-flex flex-column align-items-center shadow pl-4 pb-3 pt-3 pr-4 m-3 ${styles.teamMemberCard}`}>
      <img src={props.imgUrl} alt="Team Member" className="shadow rounded-circle" />
      <h4 className="mt-1 font-weight-600">{props.name}</h4>
      <p className="font-italic text-center font-weight-400">
        {props.about}
      </p>
    </div>
  );
};

export default TeamMember;